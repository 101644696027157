import React from "react";

const ImageList = function(props) {
  const data = props.data;
  const listItems = data.map(item => {
    const { id, text, image, url } = item;

    return (
      <li key={id}>
        <a href={url}>
          <img src={image} alt={text} />
        </a>
      </li>
    );
  });

  return <ul id="image-list">{listItems}</ul>;
};

export default ImageList;
