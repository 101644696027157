import React from "react";
import "./styles.css";
import imageData from "./data.json";
import ImageList from "./components/image-list";
import withWindowDimensions from "./components/with-window-dimension";

class App extends React.Component {
  getData() {
    var data;
    const { isSmall, isMedium, isLarge, isXLarge } = this.props;

    switch (true) {
      case isSmall:
        data = imageData.small;
        break;
      case isMedium:
        data = imageData.medium;
        break;
      case isLarge:
        data = imageData.large;
        break;
      case isXLarge:
        data = imageData.xlarge;
        break;
      default:
        data = [];
        break;
    }

    return data;
  }

  render() {
    const data = this.getData();

    return (
      <div className="App">
        <ImageList data={data} />
      </div>
    );
  }
}

export default withWindowDimensions(App);
